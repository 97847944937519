// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appUrl: 'https://eldx.qa.zonarsystems.net',
  authEnv: 'qa',
  prefix: 'qa',
  name: 'staging',
  region: 'NA',
  complianceApplicationId: '7b848b7a-621c-4690-8f18-a59bf17b6edc',
  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: 'lEBGaNMVMopAVlUbYPsvB9YHjaHXU1i0',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    applicationId: '05abeb96-e24e-4846-8703-035411d45fb0',
    defaultZonarRole: 'd64e4cb5-0f61-42fc-9ad0-506254dd6393',
    useRefreshTokens: true
  },
  urls: {
    udlApi: `/udlapi`,
    baseApi: `https://api-qa.zonarsystems.net`,
    externalApi: `https://api-qa.zonarsystems.net/core/v1`,
    complianceUrl: `https://compliance.staging.zonarsystems.net`,
    driverLogsApi: '/driver-logs-api',
    legacyEntityApi: '/legacy-entity-api',
    hosReportApi: `/hos-report-api`,
    GTC4: `https://gtc4qa-cloud.zonarsystems.net/`
  },
  helpDocs: {
    UDL: `https://support.zonarsystems.net/hc/en-us/articles/4414469986701-Unidentified-Driver-Logs-UDLs-`,
    UDL_AutoAssignYM: `https://support.zonarsystems.net/hc/en-us/articles/16961143878925-Auto-Assigning-UDLs-in-Yards-or-Zones`,
    ELDx_helpDocs: `https://support.zonarsystems.net/hc/en-us/articles/24556147665293-Driver-Logs-for-ELD-New`,
    NDR: `https://support.zonarsystems.net/hc/en-us/articles/17999726420365`
  },
  production: false,
  hereMaps: {
    hereApiKey: undefined,
    hereV3ApiKey: undefined,
    useV3: true,
    // hereUrl: '/map-data/{s}/maptile/2.1' v2 url
    hereUrl: '/map-data-v3'
  },
  datadog: {
    applicationId: 'b3fd3a90-06ed-48ca-9023-9cba94d00a02',
    clientToken: 'pub8a652e107235c257e7f966cc72f1784e',
    site: 'us5.datadoghq.com',
    service: 'eldx',
  },
  devCycle: {
    clientId: 'dvc_client_931944ae_1bdd_4034_a82f_081de48407a9_a5e52b5'
  },
  excludeAccounts: []
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
